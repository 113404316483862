import * as _ from 'lodash'
import {el, mount} from "redom";
import { first } from 'lodash'
import {validate, single as validate_single} from "validate.js";

enum TagName {
    SELECT = 'SELECT',
    INPUT = 'INPUT'
}

// This form validator is based on Bootstrap 4 form structure as
// this will add error messages inside .input-group and display indicators
class FormValidator {
    private _form: Element
    private _inputs: NodeListOf<Element>
    private _selects: NodeListOf<Element>
    private _showValidIndicator: boolean = true
    private _showInvalidMessages: boolean = true
    constraints: object = {}

    constructor(form: Element, formInputs: NodeListOf<Element>) {
        this._form = form
        this._inputs = formInputs
    }


    /**
     * Allow to validate a single input form
     * usage:
     * const validate = FormValidator.singleValidation(input, constants)
     * if (validate) { //action }
     *
     * @param input
     * @param constants
     */
    static singleValidation(input: HTMLInputElement, constants: Object) {
        let errors = validate_single(input.value, constants)
        if (errors) {
            FormValidator.renderInputErrors(input, first(errors))
            return false
        }
        return true
    }

    /**
     * Allow to render error input manually
     *
     * @param input
     * @param message
     */
    static renderInputErrors(input: HTMLInputElement, message: string) {
        let parent = input.closest('.form-group'),
            messages = parent.querySelector('.invalid-feedback')

        input.classList.remove('is-invalid')
        if (messages) messages.remove()

        input.classList.add('is-invalid')
        mount(parent, el('.invalid-feedback', message))
    }

    registerOnSubmit(onErrorsCallback: Function, onSuccessCallback: Function) {
        const self = this

        self._form.addEventListener('submit', function (ev) {
            ev.preventDefault()
            self.onValidation(onErrorsCallback, onSuccessCallback)
        })
    }

    registerOnClick(element: HTMLElement, onErrorsCallback: Function, onSuccessCallback: Function) {
        element.onclick = () => {
            this.onValidation(onErrorsCallback, onSuccessCallback)
        }
    }

    private onValidation(onErrorsCallback: Function, onSuccessCallback: Function) {
        const errors = validate(this._form, this.constraints);
        const self = this

        self._inputs.forEach(function (input) {
            // @todo: error input.name on compile but it works
            // @ts-ignore
            self.showInputErrors(input, errors && errors[input.name])
        })

        if (errors) {
            if (typeof onErrorsCallback == 'function') onErrorsCallback(self._form, errors)
        } else {
            // submit
            if (typeof onSuccessCallback == 'function') onSuccessCallback(self._form, errors)
        }
    }

    showInputErrorsOnLoad(){
        const invalidInputs = document.getElementsByClassName('field_with_errors')

        _.each(Array.from(invalidInputs), function(e){
            let errorInput = e.querySelector('input')

            if(errorInput){
                errorInput.classList.add('is-invalid')
            }
        })
    }

    setValidIndicator(show: boolean) {
        this._showValidIndicator = show
    }

    forceInputErrors(input: HTMLInputElement, errors) {
        this.showInputErrors(input, errors)
    }

    private showInputErrors(input, errors) {
        let parent = input.parentNode,
            messages = parent.querySelectorAll('.invalid-feedback')

        if(input.tagName === TagName.SELECT) {
            input = input.parentNode;
            parent = input.parentNode.parentNode.parentNode;
            messages = parent.querySelector('.invalid-feedback')
        }

        input.classList.remove('is-invalid')
        if (messages) {
            messages.forEach(function (message) {
                message.remove()
            })
        }

        if (errors) {
            input.classList.add('is-invalid')
            if (this._showInvalidMessages) {
                errors.forEach(function (error) {
                    mount(parent, el('.invalid-feedback', error))
                })
            }
        } else {
            input.classList.remove('is-invalid')
            if (this._showValidIndicator) input.classList.add('is-valid')
        }
    }
}

export default FormValidator