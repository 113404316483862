import {el, mount, text, list, List} from 'redom'
import {each, debounce, set, filter, includes, compact, merge, differenceBy, slice} from 'lodash'
import Component from 'app/interfaces/component'
import * as Agent from 'superagent'
import I18n from 'i18n'
import { GeneralHelper } from 'app/helpers/general_helper'

interface ItemData {
    id: number
    name: string
    role: string
    value?: boolean
    organization_name?: string
    region_id?: number,
    selected?: boolean,
    disabled?: boolean
}

class Item {
    data: any = {}
    el: HTMLElement
    input: HTMLInputElement
    label: HTMLElement
    name: HTMLElement
    role: HTMLElement

    constructor() {
        this.el = el('.item',
            el('.form-check',
                this.input = el('input.form-check-input', {type: 'checkbox'}) as HTMLInputElement,
                this.label = el('label.form-check-label',
                    this.name = el('.name'),
                    this.role = el('.role'),
                )
            )
        )

        this.label.onclick = (ev) => {
            this.input.click()
            ev.stopPropagation()
        }

        this.input.onchange = () => {
            this.data.value = this.input.checked
            this.update(this.data, this.data.index)

            // Dispatch custom event to be captured by parent
            this.input.dispatchEvent(new CustomEvent('itemchange', {
                detail: this.data
            }))
        }
    }

    update(data, index?) {
        this.data = data
        this.data.index = index
        this.input.checked = this.data.value
        this.input.disabled = this.data.disabled
        this.name.textContent = data.organization_name
        this.role.textContent = data.role
    }
}

interface FilterOptions {
    disableSelectAll?: boolean
    maxSelection?: null | number
}

/**
 * Partner Filter Component
 *
 * @param container: parent element that will contain the component
 * @param ajaxUrl?: URL for AJAX request to populate the list
 * @param setSelected?: array of UIDs to pre-select the list
 * @param options?: accepts:
 *                      disableSelectAll: boolean, default: false
 *                      maxSelection: null, number, default: null
 */
class PartnerFilter implements Component {
    private readonly _container: HTMLElement
    private _activeText: HTMLElement
    private _filterType: HTMLElement
    private _filterButton: HTMLElement
    private _searchInput: HTMLInputElement
    private _selectAll: HTMLInputElement
    private _selectAllLabel: HTMLElement
    private _setFilterButton: HTMLElement
    private _filter: HTMLElement
    private _dropdown: HTMLElement
    private _button: HTMLElement
    private _itemListContainer: HTMLElement
    private _data: ItemData[]
    private _filteredData: ItemData[]
    private _itemList: List
    private _selectedIds: Object[] = []
    private _ajaxURL: string
    private _options: FilterOptions
    private _dataLength: Number

    // @fixme: consider a better definition for callbacks
    private _onFilterChangeCallback: (args) => void = () => null
    private _onLoadCallback: (args) => void = () => null
    private _onErrorCallback: (args) => void = () => null

    constructor(container: HTMLElement, ajaxURL?: string, setSelected?: Object[], options?: FilterOptions) {
        this._container = container
        this._ajaxURL = ajaxURL
        this._selectedIds = compact(setSelected)

        const defaultOptions: FilterOptions = {
            disableSelectAll: false,
            maxSelection: null,
        }
        this._options = merge(defaultOptions, options)

        if (this._ajaxURL) {
            Agent
                .get(this._ajaxURL)
                .on('error', (err) => {
                    this._onErrorCallback(err)
                })
                .then((resp) => {
                    this.setData(JSON.parse(resp.text))
                })
        }

        this._button = this.drawButton()
        this._onLoadCallback(this)
    }

    setData(data: ItemData[]) {
        this._data = data
        this._filteredData = data
        let selectedCount = 0
        let selectedIds = []
        each(this._filteredData, (item) => {
            if (this._options.maxSelection) {
                selectedIds = slice(this._selectedIds, 0, this._options.maxSelection)
            } else {
                selectedIds = this._selectedIds
            }

            if (item.selected == true) {
                set(item, 'value', true)
                selectedCount++
            } else if (this._options.maxSelection && (this._options.maxSelection <= selectedIds.length)) {
                set(item, 'disabled', true)
            }
        })

        this._itemList.update(this._data)
        if (selectedCount == this._data.length) {
            this._selectAll.checked = true
        }

        this.setActiveText(selectedCount)
    }

    show() {
        this._button.classList.remove('d-none')
    }

    hide() {
        this._button.classList.add('d-none')
    }

    onLoad(callback: (args) => void) {
        this._onLoadCallback = callback
    }

    onChange(callback: (activeFilters: ItemData[]) => void) {
        this._onFilterChangeCallback = callback
    }

    onError(callback: (error) => void) {
        this._onErrorCallback = callback
    }

    private drawButton() {
        GeneralHelper.getCurrentLanguage()
        this._filterType = el('.type', text(I18n.t('views.global.filter_partner.partner')))
        this._activeText = el('.active', text(I18n.t('views.global.filter_partner.all_partners')))
        this._filterButton = el('button.btn', this._filterType, this._activeText,
            {'data-toggle': 'dropdown', 'data-display': 'static'})
        this._searchInput = el('input.input-search.form-control.form-control-sm', {placeholder: I18n.t('views.global.filter_partner.search_partner')+'...'}) as HTMLInputElement

        this._dropdown = el('.dropdown-menu.dropdown-menu-partner',
            el('fieldset.position-relative', this._searchInput, el('.form-control-position', el('i.fa.fa-search'))),
            el('.dropdown-divider'),
            el('.select-all.form-check',
                this._selectAll = el('input.form-check-input', {type: 'checkbox'}) as HTMLInputElement,
                this._selectAllLabel = el('label.form-check-label', text(I18n.t('views.global.filter_partner.all_partners')))
            ),
            el('.dropdown-divider'),
            this._itemListContainer = el('.list-container', this._itemList = this.drawItemList(this._filteredData)),
            el('.dropdown-divider'),
            this._setFilterButton = el('button.btn.btn-primary.btn-block', I18n.t('views.global.filter_partner.set_filter')),
        )
        this._filter = el('.filter.filter-partner', this._filterButton, this._dropdown)

        if (this._options.disableSelectAll)
            this._selectAll.disabled = true

        this.addEventListeners()
        mount(this._container, this._filter)
        return this._filterButton
    }

    private addEventListeners() {
        // On search input change (with debounce)
        this._searchInput.oninput = debounce(() => {
            this._filteredData = this.searchData(this._searchInput.value, this._data)
            this._itemList.update(this._filteredData)
        }, 500)

        // On select all items
        this._selectAll.onchange = () => {
            const checked = this._selectAll.checked
            each(this._filteredData, function (item) {
                set(item, 'value', checked)
            })
            this._itemList.update(this._filteredData)
        }

        this._selectAllLabel.onclick = (ev) => {
            this._selectAll.click()
            ev.stopPropagation()
        }

        // On set filter button
        this._setFilterButton.onclick = () => {
            const selectedItems = this.getSelectedItems()
            this._onFilterChangeCallback(selectedItems)
            this.setActiveText(selectedItems.length)
        }

        this._dropdown.onclick = (ev) => {
            ev.stopPropagation()
        }

        this._itemListContainer.addEventListener('itemchange', (e) => {
            const selectedItems = this.getSelectedItems()
            if (this._options.maxSelection && (selectedItems.length >= this._options.maxSelection)) {
                const unselectedItems = differenceBy(this._filteredData, selectedItems) as ItemData[]
                each(unselectedItems, function (item) {
                    set(item, 'disabled', true)
                })
            } else {
                each(this._filteredData, function (item) {
                    set(item, 'disabled', false)
                })
            }

            this._itemList.update(this._filteredData)
            e.stopPropagation()
        }, true)
    }

    private getSelectedItems() {
        return filter(this._data, function (item) {
            if (item.value) return item
        });
    }

    private drawItemList(data) {
        let items = list('.filter-list.scrollable-container.ps', Item)
        items.update(data)
        return items
    }

    private searchData(keyword, data) {
        return filter(data, function (item) {
            return includes(item.organization_name.toLowerCase(), keyword.toLowerCase())
        })
    }

    private setActiveText(selected: number) {
        if (selected == this._data.length) {
            this._activeText.textContent = I18n.t('views.global.filter_partner.all_partners')
        } else if (selected > 0) {
            this._activeText.textContent = `${selected} Selected`
        } else {
            this._activeText.textContent = I18n.t('views.global.filter_partner.all_partners')
        }
    }
}

export default PartnerFilter
