import {el, mount, text, list, List, html} from 'redom'
import {each, debounce, set, filter, includes, compact, merge, differenceBy, slice} from 'lodash'
import Component from 'app/interfaces/component'
import * as Agent from 'superagent'
import i18n from 'i18n'
import { GeneralHelper } from 'app/helpers/general_helper'

class ItemData {
    id: number
    name: string
    role: string
    value?: boolean
    organization_name?: string
    region_id?: number
    selected?: boolean
    disabled?: boolean
    hidden?: boolean
}

class Item {
    data: any = {}
    el: HTMLElement
    input: HTMLInputElement
    label: HTMLElement
    name: HTMLElement
    role: HTMLElement
    hidden: boolean

    constructor() {
        this.el = el('.item',
            el('.form-check',
                this.input = el('input.form-check-input', {type: 'checkbox'}) as HTMLInputElement,
                this.label = el('label.form-check-label',
                    this.name = el('.name'),
                    this.role = el('.role'),
                )
            )
        )

        this.label.onclick = (ev) => {
            this.input.click()
            ev.stopPropagation()
        }

        this.input.onchange = () => {
            this.data.value = this.input.checked
            if (this.data.value) {
                let selectedOrgIds = OrganizationFilter._selectedOrganizationsTemp.map((item) => item.id).filter((value, index, self) => self.indexOf(value) === index);
                !selectedOrgIds.includes(this.data.id) && OrganizationFilter._selectedOrganizationsTemp.push(this.data);
            } else {
                OrganizationFilter._selectedOrganizationsTemp = OrganizationFilter._selectedOrganizationsTemp.filter((item) => item.id != this.data.id)
            }
            OrganizationFilter._selectedItems = OrganizationFilter._selectedOrganizationsTemp.length

            OrganizationFilter.toggleFilterLabel(OrganizationFilter._selectedItems)
            this.update(this.data, this.data.index)

            // Dispatch custom event to be captured by parent
            this.input.dispatchEvent(new CustomEvent('itemchange', {
                detail: this.data
            }))
        }
    }

    update(data, index?) {
        this.data = data
        this.data.index = index
        this.input.checked = this.data.value
        this.input.disabled = this.data.disabled
        this.name.textContent = data.organization_name
        this.role.textContent = data.role
        this.el.hidden = this.data.hidden
    }
}

class SelectedItem extends Item {
    constructor() {
        super()

        this.el = el('.item.selected-organizations',
            el('.form-check',
                this.input = el('input.form-check-input', {type: 'checkbox'}) as HTMLInputElement,
                this.label = el('label.form-check-label',
                    this.name = el('.name'),
                    this.role = el('.role'),
                )
            )
        )

        this.label.onclick = (ev) => {
            this.input.click()
            ev.stopPropagation()
        }

        this.input.onchange = () => {
            this.data.value = this.input.checked
            if (this.data.value) {
                let selectedOrgIds = OrganizationFilter._selectedOrganizationsTemp.map((item) => item.id).filter((value, index, self) => self.indexOf(value) === index);
                !selectedOrgIds.includes(this.data.id) && OrganizationFilter._selectedOrganizationsTemp.push(this.data);
            } else {
                OrganizationFilter._selectedOrganizationsTemp = OrganizationFilter._selectedOrganizationsTemp.filter((item) => item.id != this.data.id)
            }
            OrganizationFilter._selectedItems = OrganizationFilter._selectedOrganizationsTemp.length
            OrganizationFilter.toggleFilterLabel(OrganizationFilter._selectedItems)

            this.update(this.data, this.data.index)

            // Dispatch custom event to be captured by parent
            this.input.dispatchEvent(new CustomEvent('itemchange', {
                detail: this.data
            }))
        }
    }

    update(data, index?) {
        this.data = data
        this.data.index = index
        this.input.checked = this.data.value
        this.input.disabled = this.data.disabled
        this.name.textContent = data.organization_name
        this.role.textContent = data.role
        this.el.hidden = this.data.hidden
    }
}

interface FilterOptions {
    disableSelectAll?: boolean
    maxSelection?: null | number
}

const FILTER_BUTTON_VALUES = [
    '123',
    'A-D',
    'E-I',
    'J-O',
    'P-Q',
    'R-Z'
]

/**
 * Organizations Filter Component
 *
 * @param container: parent element that will contain the component
 * @param ajaxUrl?: URL for AJAX request to populate the list
 * @param setSelected?: array of UIDs to pre-select the list
 * @param options?: accepts:
 *                      disableSelectAll: boolean, default: false
 *                      maxSelection: null, number, default: null
 */
class OrganizationFilter implements Component {
    private readonly _container: HTMLElement
    private _activeText: HTMLElement
    private _filterType: HTMLElement
    private _filterButton: HTMLElement
    private _nextButton: HTMLElement
    private _prevButton: HTMLElement
    private _selectedLabel: HTMLElement
    private _resetToDefaultButton: HTMLElement
    private _itemListDivider: HTMLElement
    private _filter: HTMLElement
    private _combinedFilterButtons: HTMLElement
    private static _searchResultLabel: HTMLElement
    private static _searchInput: HTMLInputElement
    private _selectAllElement: HTMLElement
    private _selectAll: HTMLInputElement
    private _selectAllLabel: HTMLElement
    private static _setFilterButton: HTMLElement
    private _dropdown: HTMLElement
    private _button: HTMLElement
    private _itemListContainer: HTMLElement
    private _selectedItemListContainer: HTMLElement
    private static _data: ItemData[]
    private static _filteredData: ItemData[]
    private static _selectedData: ItemData[]
    private static _itemList: List
    private static _selectedItemList: List
    private _ajaxURL: string
    private static _filterAjaxURL: string
    private _options: FilterOptions
    private static _alphabetFiltered: string = ''
    public static _selectedItems: number = 0
    private static _isAllSelected: boolean
    protected static _pageInfo: Object = {is_last_page: false, page: 1}
    protected static _paginationState: Object[] = [{}]
    protected _urlParams: URLSearchParams
    public static _selectedOrganizationsTemp: any = []
    public static _currentSelectedCount: number = 0
    

    // @fixme: consider a better definition for callbacks
    private _onFilterChangeCallback: (args) => void = () => null
    private _onLoadCallback: (args) => void = () => null
    private _onErrorCallback: (args) => void = () => null

    constructor(container: HTMLElement, ajaxURL?: string, setSelected?: Object[], options?: FilterOptions) {
        this._container = container
        this._ajaxURL = ajaxURL
        OrganizationFilter._filterAjaxURL = ajaxURL

        const defaultOptions: FilterOptions = {
            disableSelectAll: true,
            maxSelection: null
        }
        this._options = merge(defaultOptions, options)
        this._urlParams = new URLSearchParams(location.search)
        
        if (this._ajaxURL) {
            Agent
                .get(this._ajaxURL)
                .on('error', (err) => {
                    this._onErrorCallback(err)
                })
                .then((resp) => {
                    this.loaded()
                    let respObj = JSON.parse(resp.text)
                    this.setData(respObj['data'])
                    this.resetResetLabelAndButton()

                    
                    this.redrawFilterChange(respObj)
                })
        }

        this._button = this.drawButton()
        this._onLoadCallback(this)
    }

    setData(data: ItemData[], hideSelectedInList = true, keyword = '') {
        OrganizationFilter._data = data
        let selectedData = []
        let itemList = []
        let selectedCount = 0
        each(data, (item) => {
            if (item.selected == true) {
                // push to selected list if data was selected before
                let selectedOrgIds = OrganizationFilter._selectedOrganizationsTemp.map((item) => item.id).filter((value, index, self) => self.indexOf(value) === index);
                !selectedOrgIds.includes(item.id) && OrganizationFilter._selectedOrganizationsTemp.push(item);

                item.value = true
                selectedCount++
                selectedData.push(Object.assign({}, item))
            }

            let forFilterList = Object.assign({}, item)
            forFilterList.hidden = false
            // hide selected organization in list organization
            if (forFilterList.selected) {
                forFilterList.hidden = hideSelectedInList
            }
            // hide selected organization when name is not match with keyword
            if (!includes(forFilterList.organization_name.toLowerCase(), keyword.toLowerCase())) {
                forFilterList.hidden = true
            }
            itemList.push(Object.assign({}, forFilterList))
        })


        selectedCount = OrganizationFilter._selectedOrganizationsTemp.length

        OrganizationFilter._itemList.update(itemList)
        OrganizationFilter._selectedItemList.update(selectedData)

        if (selectedCount == data.length) {
            this._selectAll.checked = true
            OrganizationFilter._isAllSelected = true
        }

        if (data.length == 0) {
            OrganizationFilter._searchResultLabel.classList.remove('d-none')
            OrganizationFilter._searchResultLabel.textContent = i18n.t('views.global.organization_filter.not_found')
        }

        OrganizationFilter._selectedItems = selectedCount
        OrganizationFilter.toggleFilterLabel(selectedCount)
    }

    show() {
        this._button.classList.remove('d-none')
    }

    hide() {
        this._button.classList.add('d-none')
    }

    onLoad(callback: (args) => void) {
        this._onLoadCallback = callback
    }

    onChange(callback: (activeFilters: ItemData[]) => void) {
        this._onFilterChangeCallback = callback
    }

    onError(callback: (error) => void) {
        this._onErrorCallback = callback
    }

    private drawButton() {
        GeneralHelper.getCurrentLanguage()
        this._filterType = el('.type', i18n.t('views.global.organization_filter.organizations'))
        this._activeText = el('.active', i18n.t('views.global.organization_filter.all_orgs'))
        this._filterButton = el('button.btn', this._filterType, this._activeText,
            {'data-toggle': 'dropdown', 'data-display': 'static'})
        this._resetToDefaultButton = el('a.reset-to-default-button', i18n.t('views.global.organization_filter.reset'))
        this._selectedLabel = el('fieldset.position-relative.text.text-muted.mb-1.d-none', el('.row', el('.col-3.small', i18n.t('views.global.organization_filter.selected')+':'), el('.col-9.text-right.small', this._resetToDefaultButton)))
        this._itemListDivider = el('.dropdown-divider.mt-0.d-none')
        
        OrganizationFilter._searchInput = el('input.input-search-organization.form-control.form-control-sm', {placeholder: i18n.t('views.global.organization_filter.search')}) as HTMLInputElement
        
        let filterButtons = []
        FILTER_BUTTON_VALUES.forEach(function(item) {
            filterButtons.push(el('a.org-filter-buttons', { "data-value": (item == '123' ? '^A-Z' : item) }, text(item)))
        })

        OrganizationFilter._searchResultLabel = el('fieldset.position-relative.text.text-muted.mb-1.d-none', i18n.t('views.global.organization_filter.search_result'))
        this._combinedFilterButtons = el('.col-9.text-right', ...filterButtons)
        this._dropdown = el('.dropdown-menu.filter-organization-dropdown',
            el('fieldset.position-relative.text.text-muted', el('.row', el('.col-3', i18n.t('views.global.organization_filter.filter') + ":"), this._combinedFilterButtons)),
            el('.dropdown-divider.mb-0'),
            el('fieldset.position-relative.has-icon-left', OrganizationFilter._searchInput, el('.form-control-position', el('i.fa.fa-search'))),
            el('.dropdown-divider.mt-0'),
            this._selectedLabel,
            this._selectedItemListContainer = el('.list-container', OrganizationFilter._selectedItemList = OrganizationFilter.drawSelectedItemList(OrganizationFilter._filteredData)),
            this._itemListDivider,
            OrganizationFilter._searchResultLabel,
            this._selectAllElement = el('.select-all.form-check',
                this._selectAll = el('input.form-check-input', {type: 'checkbox'}) as HTMLInputElement,
                this._selectAllLabel = el('label.form-check-label', i18n.t('views.global.organization_filter.all_orgs'))
            ),
            this._itemListContainer = el('.list-container.list-container-organizations', OrganizationFilter._itemList = OrganizationFilter.drawItemList(OrganizationFilter._filteredData)),
            this.renderPagination(),
            el('.dropdown-divider'),
            OrganizationFilter._setFilterButton = el('button.btn.btn-primary.btn-block', i18n.t('views.global.organization_filter.filter'))
        )
        
        this._filter = el('.filter.filter-organizations', this._filterButton, this._dropdown)

        if (this._options.disableSelectAll){
            this._selectAll.disabled = true
            this._selectAllElement.hidden = true
        }

        this.loading()

        mount(this._container, this._filter)
        this.addEventListeners()
        return this._filterButton
    }

    protected renderPagination() {
        this._nextButton = el('a.pagination-button.small.hidden.ml-1', {class: 'link', 'data-page': 'next'}, i18n.t('views.global.organization_filter.next'))
        this._prevButton = el('a.pagination-button.small.hidden', {class: 'link', 'data-page': 'prev'}, i18n.t('views.global.organization_filter.prev'))

        this._nextButton.onclick = () => {
            this._urlParams = new URLSearchParams(location.search)
            this._urlParams.set('page_action', 'next')
            this._urlParams.set('pagination_state', JSON.stringify(OrganizationFilter._paginationState))
            this._urlParams.set('page', OrganizationFilter._pageInfo['page'])
            this._urlParams.set('keyword', OrganizationFilter._searchInput.value)
            this._urlParams.set('regex_filter', OrganizationFilter._alphabetFiltered)
            this.filterChangeEventHandler()
        }

        this._prevButton.onclick = () => {
            this._urlParams = new URLSearchParams(location.search)
            this._urlParams.set('page_action', 'prev')
            this._urlParams.set('pagination_state', JSON.stringify(OrganizationFilter._paginationState))
            this._urlParams.set('page', OrganizationFilter._pageInfo['page'])
            this._urlParams.set('keyword', OrganizationFilter._searchInput.value)
            this._urlParams.set('regex_filter', OrganizationFilter._alphabetFiltered)
            this.filterChangeEventHandler()
        }

        return el('.row#paginator', [
            el('.col-12.text-right', [
                this._prevButton,
                this._nextButton
            ])
        ])
    }

    private filterChangeEventHandler(hideSelectedInList = true) {
        this.loading()

        Agent
            .get(this._ajaxURL + '?' + this._urlParams.toString())
            .on('error', (err) => {
                this._onErrorCallback(err)
            })
            .then((resp) => {
                this.loaded();
                let respObj = JSON.parse(resp.text)
                this.setData(respObj['data'], hideSelectedInList)
                OrganizationFilter.resetSearchLabel(respObj['data'].length)
                
                this.redrawFilterChange(respObj)
            })
    }

    private redrawFilterChange(respObj) {
        OrganizationFilter._paginationState = respObj['pagination_state']
        OrganizationFilter._pageInfo = respObj['page_info']
        this._nextButton.classList.add('hidden')
        this._prevButton.classList.add('hidden')
        OrganizationFilter._pageInfo['page'] <= 1 ? this._prevButton.classList.add('hidden') : this._prevButton.classList.remove('hidden')
        OrganizationFilter._pageInfo['is_last_page'] ? this._nextButton.classList.add('hidden') : this._nextButton.classList.remove('hidden')
    }

    private addEventListeners() {
        // On search input change (with debounce)
        OrganizationFilter._searchInput.oninput = debounce(() => {
            this._urlParams = new URLSearchParams(location.search)
            this._urlParams.set('page', OrganizationFilter._pageInfo['page'])
            this._urlParams.set('keyword', OrganizationFilter._searchInput.value)
            this._urlParams.set('regex_filter', OrganizationFilter._alphabetFiltered)

            this.loading()
            let hideSelectedInList = (OrganizationFilter._searchInput.value == '')
            Agent
                .get(this._ajaxURL + '?' + this._urlParams.toString())
                .on('error', (err) => {
                    this._onErrorCallback(err)
                })
                .then((resp) => {
                    this.loaded()
                    let respObj = JSON.parse(resp.text)
                    this.setData(respObj['data'], hideSelectedInList, OrganizationFilter._searchInput.value)
                    OrganizationFilter.resetSearchLabel(respObj['data'].length, i18n.t('views.global.organization_filter.no_results'))
                    this.redrawFilterChange(respObj)
                })
        }, 500)
        // On select all items
        this._selectAll.onchange = () => {
            const checked = this._selectAll.checked
            each(OrganizationFilter._filteredData, function (item) {
                set(item, 'value', checked)
            })
            OrganizationFilter._itemList.update(OrganizationFilter._filteredData)
        }

        this._selectAllLabel.onclick = (ev) => {
            this._selectAll.click()
            ev.stopPropagation()
        }

        // On set filter button
        OrganizationFilter._setFilterButton.onclick = () => {
            this.loading()
            const selectedItems = this.getSelectedItems()
            this._onFilterChangeCallback(selectedItems)
            this.setActiveText(selectedItems.length)
        }

        this._dropdown.onclick = (ev) => {
            ev.stopPropagation()
        }

        let inactiveFilterButtons = document.querySelectorAll('.org-filter-buttons') as NodeListOf<HTMLElement>
        inactiveFilterButtons.forEach(this.filterButtonEvent.bind(this))

        this._resetToDefaultButton.onclick = (ev) => {
            this._onFilterChangeCallback([])
            this.setActiveText(null)
        }
    }

    private getSelectedItems() {
        let selectedOrganizationEl = document.querySelectorAll('.selected-organizations input') as NodeListOf<HTMLInputElement>
        let unselectedOrganizations = []
        selectedOrganizationEl.forEach((selectedItem) => {
            if (!selectedItem.checked) {
                unselectedOrganizations.push(selectedItem.id)
            }
        })

        let selectedOrganizations = OrganizationFilter._selectedOrganizationsTemp

        return filter(selectedOrganizations, function(item) {
            // @ts-ignore
            if (unselectedOrganizations.indexOf(item.id) == -1) return item
        })
    }

    private static drawItemList(dataList) {
        let items = list('.filter-organization-list.scrollable-container-organizations.scrollable-container.ps', Item)
        items.update(dataList)
        return items
    }

    private static drawSelectedItemList(dataList) {
        let items = list('.filter-organization-list.scrollable-container-organizations.scrollable-container.ps', SelectedItem)
        items.update(dataList)
        return items
    }

    private static searchData(keyword, data) {
        if (!keyword.replace(/\s/g, '').length || keyword == '') {
            return data
        }
        
        return filter(data, function (item) {
            return includes(item.organization_name.toLowerCase(), keyword.toLowerCase())
        })
    }

    private static filterAlphabeticData(keyword, data) {
        if (!keyword.replace(/\s/g, '').length || keyword == '') {
            return data
        }

        if (keyword == '123')
            keyword = '0-9'

        var regex = new RegExp("^(["+keyword.toLowerCase()+"]\w*)"); 
        return filter(data, function(item) {
            return item.organization_name.toLowerCase().match(regex)
        })
    }

    private setActiveText(selected: number) {
        if (selected > 0 && selected != OrganizationFilter._data.length) {
            this._activeText.textContent = `${selected} Selected`
        } else {
            this._activeText.textContent = 'All Organizations'
        }
    }


    private filterButtonEvent(element) {
        element.onclick = () => {
            let filterButtons = document.querySelectorAll('.org-filter-buttons') as NodeListOf<HTMLElement>
            filterButtons.forEach(function(el){
                el.classList.remove('active')
            })

            let keyword = element.dataset.value
            if (OrganizationFilter._alphabetFiltered == keyword) {
                element.classList.remove('active')
                let filteredData = OrganizationFilter._data
                OrganizationFilter._itemList.update(filteredData)
                OrganizationFilter._alphabetFiltered = '' // clear active filter
            } else {
                element.classList.add('active')
                let filteredData = OrganizationFilter.filterAlphabeticData(keyword, OrganizationFilter._data)
                filteredData = OrganizationFilter.filterAlphabeticData(keyword, filteredData)
                OrganizationFilter._itemList.update(filteredData)
                OrganizationFilter._alphabetFiltered = keyword // set active filter
            }
            
            this._urlParams = new URLSearchParams(location.search)
            this._urlParams.set('keyword', OrganizationFilter._searchInput.value)
            this._urlParams.set('regex_filter', OrganizationFilter._alphabetFiltered)
 
            this.filterChangeEventHandler(true)
        }
    }

    private static toggleSearchLabel(textContent = null) {
        OrganizationFilter._searchResultLabel.classList.add('d-none')
        if (OrganizationFilter._searchInput.value != '' || OrganizationFilter._alphabetFiltered != '') {
            OrganizationFilter._searchResultLabel.classList.remove('d-none')
            // @ts-ignore
            let isAllSelectedHidden = OrganizationFilter._itemList.views.every((item) => item.el.hidden == true)

            // @ts-ignore
            if (OrganizationFilter._itemList.views.length == 0 || isAllSelectedHidden) {
                OrganizationFilter._searchResultLabel.textContent = i18n.t('views.global.organization_filter.not_found')
            } else if (OrganizationFilter._alphabetFiltered != '') {
                OrganizationFilter._searchResultLabel.textContent = null
                OrganizationFilter._searchResultLabel.classList.add('d-none')
            } else {
                OrganizationFilter._searchResultLabel.textContent = i18n.t('views.global.organization_filter.search_result')
            }
        }
        
        if (textContent != null) {
            OrganizationFilter._searchResultLabel.classList.remove('d-none')
            OrganizationFilter._searchResultLabel.textContent = textContent
        }
    }

    public static toggleFilterLabel(count) {
        OrganizationFilter._setFilterButton.textContent = i18n.t('views.global.organization_filter.filter') + (count > 0 ? ` (${count} ${i18n.t('views.global.organization_filter.selected')})`: '')
    }

    private static resetSearchLabel(dataFoundLength, emptyLabel = null) {
        let searchFound = dataFoundLength - OrganizationFilter._currentSelectedCount
        if (searchFound <= 0) {
            OrganizationFilter.toggleSearchLabel(emptyLabel || i18n.t('views.global.organization_filter.not_found'))
        } else {
            OrganizationFilter.toggleSearchLabel(i18n.t('views.global.organization_filter.search_result'))
            if (OrganizationFilter._searchInput.value == '') {
                OrganizationFilter.toggleSearchLabel()    
            }
        }
    }

    private resetResetLabelAndButton() {
        let selectedCount = OrganizationFilter._selectedOrganizationsTemp.length
        if (selectedCount > 0) {
            this._selectedLabel.classList.remove('d-none')
            this._itemListDivider.classList.remove('d-none')
        }
        this.setActiveText(selectedCount)
        OrganizationFilter._currentSelectedCount = selectedCount
    }

    private loading() {
        OrganizationFilter.toggleSearchLabel(i18n.t('views.global.organization_filter.loading'))
        this._itemListContainer.hidden = true
    }

    private loaded() {
        OrganizationFilter.toggleSearchLabel('')
        this._itemListContainer.hidden = false
    }

}

export default OrganizationFilter
