import {el, mount, setChildren} from 'redom'
import {map, merge, each, get} from 'lodash'
import * as $ from 'jquery'
import i18n from 'i18n'
import Modal from 'bootstrap/js/src/modal.js'

// TODO: refactoring the DialogOptions
export interface DialogOptions {
    title?: string
    subtitle?: string
    header_icon?: any
    content?: string
    border?: boolean
    footer?: boolean
    close_button?: boolean
    description?: string
    credential_id?: string
    region_id?: string
    modal_id?: string
    text_save_button?: string
    is_delete_button?: boolean
    modal_size?: null | 'modal-sm' | 'modal-lg' | 'modal-xl'
    dismissable?: boolean
}

export interface DialogButton {
    element: string
    text: string
    attrs?: object
    icon?: string

    callback?(): void
}

export interface InputAttributes {
    type?: string
    placeholder?: string
    value?: any
}

export interface DialogInput {
    element: string
    label: string
    name: string
    optional?: boolean
    attrs?: InputAttributes
}

export interface DialogNotice {
    class: string
    text: string
}

export interface DialogTab {
    label: string,
    attributes: object,
}

export interface DialogTable {
    table_attributes: object
    table_heads: any,
    table_rows: any
}

/**
 * Modal dialog implementation of Bootstrap's Modal so we can
 * easily change modal contents without too much boilerplate templates.
 *
 * This component consist of two type:
 * 1. Dialog with input form
 * 2. Dialog with only text information (confirmation dialog)
 *
 * Methods:
 * show(): open dialog
 * hide(): close dialog
 *
 * Events:
 * onSave(): called when save button is clicked
 * onCancel(): called when cancel button is clicked
 *
 * Usage:
 * new Dialog(element, options)
 *   options = { title:, content: }
 */
export class Dialog {
    protected _modal: Modal
    protected _modalElement: HTMLElement
    protected _modalTitle: string = ''
    protected _modalSubtitle: string = ''
    protected _modalHeaderIcon: string = ''
    protected _modalDescription: string = ''
    protected _modalContent: HTMLElement
    protected _modalForm: HTMLFormElement
    protected _modalPartBorder: boolean = true
    protected _modalCloseButton: boolean = true
    protected _modalId: string = null
    protected _parentElement: HTMLElement
    protected _modalHeader: HTMLElement
    protected _modalBody: HTMLElement
    protected _modalFooter: HTMLElement
    protected _modalContentAjax: Promise<Request>
    protected _dismissable: boolean = true
    protected _modalSize: any
    protected _buttonElements: HTMLButtonElement[] = []
    protected _options: DialogOptions

    protected _modalCancelCallback: (args) => void = () => null
    protected _modalSaveCallback: (args) => void = () => null
    protected _modalHideCallback: (args) => void = () => null
    protected _formChangeCallback: (args) => void = () => null
    protected _modalShownCallback: () => void = () => null

    protected _buttons: DialogButton[] = [
        {
            element: 'button.btn.btn-outline-primary.btn-sm.btn-cancel',
            text: i18n.t('button.cancel'),
            callback: this.cancelButtonClick,
            attrs: {'data-dismiss': 'modal', type: 'button'}
        },
        {
            element: 'button.btn.btn-primary.btn-sm.btn-save',
            text: i18n.t('button.continue'),
            callback: this.saveButtonClick,
        }
    ]

    protected _notices: DialogNotice[] = [
    ]

    protected _inputs: DialogInput[] = [
    ]

    protected _inputElements: Element[] = []

    protected _tabs: DialogTab[] = [
    ]

    protected _tables: DialogTable[] = [
    ]

    protected _customInputs: HTMLElement

    constructor(elem: HTMLElement, options?: DialogOptions) {
        let defaultOptions = {}
        this._parentElement = elem

        if (this._parentElement) {
            defaultOptions = {
                title: elem.getAttribute('data-dialog-title'),
                content: elem.getAttribute('data-dialog-content'),
                description: elem.getAttribute('data-dialog-description'),
                border: false,
                close_button: false,
                modal_id: null,
                modal_size: null,
                dismissable: true,
            }
        }

        const result = merge(defaultOptions, options)

        this._options = result
        this._modalTitle = result.title
        this._modalSubtitle = result.subtitle
        this._modalContent = (result.content) ? el('p', (el) => {el.innerHTML = result.content}) : null
        this._modalDescription = result.description
        this._modalPartBorder = result.border
        this._modalCloseButton = result.close_button
        this._modalId = result.modal_id
        this._modalSize = result.modal_size
        this._modalHeaderIcon = result.header_icon
        this._dismissable = result.dismissable

        if (result.text_save_button) {
            this._buttons[1].text = result.text_save_button
        }

        if (result.is_delete_button) {
            this._buttons[1].element = 'button.btn.btn-danger.btn-sm'
        }

        // IMPORTANT: derived classes must call constructModal after
        //            invoking this c-tor.
    }

    show(resetInputValues: boolean = true) {
        if (resetInputValues) this.resetInputValues()
        this._modal.show()
    }

    hide() {
        this._modal.hide()
    }

    onChange(callback: (formData: object) => void) {
        this._formChangeCallback = callback
    }

    onLoad(callback: (...args: any) => void) {
    }

    onCancel(callback: (formData: object) => void) {
        this._modalCancelCallback = callback
    }

    onSave(callback: (formData: object) => void) {
        this._modalSaveCallback = callback
    }

    appendContent(content: HTMLElement[]) {
        setChildren(this._modalBody, content)
    }

    protected constructModal(elem: HTMLElement) {
        const footer = this._buttons.length ? this.constructFooter() : null,
            backdrop = this._dismissable ? true : 'static'

        let modalClass = '.modal.fade'
        if (this._modalId) {
            modalClass = modalClass + this._modalId
        }

        let modalDialogEl
        this._modal = new Modal(
            this._modalElement = el(modalClass,
                modalDialogEl = el('.modal-dialog.modal-dialog-centered.modal-dialog-scrollable',
                    el('.modal-content',
                        this._modalHeader = this.constructHeader(),
                        this._modalBody = this.constructBody(elem),
                        this._modalFooter = footer
                    )
                ),
                {'aria-hidden': true, tabindex: '-1'}),
            {
                backdrop: backdrop
            }
        )

        if (this._modalSize) {
            modalDialogEl.classList.add(this._modalSize)
        }

        /**
         * @todo: Do we need hide event?
         * Since bootstrap modal is implementing a non-native events using
         * jQuery.trigger() we also have to use it here.
         */
        $(this._modalElement).on('hide.bs.modal', (ev) => {
            const formValues = $(this._modalForm).serialize()
            this._modalHideCallback(formValues)
        })

        $(this._modalElement).on('shown.bs.modal', (ev) => {
            this._modalShownCallback()
        })
    }

    onHide(callback: (formData: object) => void) {
        this._modalHideCallback = callback
    }

    onShown(callback: () => void) {
        this._modalShownCallback = callback
    }

    protected constructHeader() {
        let elements = []

        if (this._modalCloseButton) {
            elements.push(el('button.close', el('span', '×',
                {'aria-hidden': 'true'}),
                {'data-dismiss': 'modal', type: 'button'}
                )
            )
        }

        if (this._modalHeaderIcon) {
            elements.push(el('.row.align-items-center',
                el('.col-2.text-center.p-0',
                    this._modalHeaderIcon
                ),
                el('.col-10',
                    el('h3.modal-title.text-truncate', this._modalTitle),
                    el('.small.text-muted.text-truncate', this._modalSubtitle, {style:{'text-transform':'capitalize'}}),
                    {style: {'padding':'0.5em'}}
                )
            ))
        } else {
            elements.push(
                el('.wrapper-header',
                    el('.truncate-ellipsis',
                        el('h3.modal-title', this._modalTitle)))
            )
        }


        if (this._modalDescription){
            elements.push(
                el('.wrapper-header-description',
                    el('.modal-description', this._modalDescription)
                )
            )
        }

        if (this._modalPartBorder) {
            return el('.modal-header', elements)
        } else {
            return el('.modal-header.border-0', elements)
        }
    }

    protected constructBody(elem: HTMLElement) {
        let elements = []

        if (this._notices.length) {
            let notices = map(this._notices, (item) => {
                return el(item.class,
                    el('.alert-content', item.text)
                )
            })
            elements.push(notices)
        }

        // This is for tabs with tables only (BOTH OF table and tables should be present)
        if (this._tabs.length){
            let lists = []
            let contents = []

            map(this._tabs, (item) => {
                lists.push(
                    el('li.nav-item',
                        el('a.nav-link', item.attributes, item.label)
                    )
                )
            })

            contents.push(
                el('.text-center',
                    el('.spinner-border', {role: 'status'},
                        el('span.sr-only', 'Loading...')
                    )
                )
            )

            elements.push(el('ul.nav.nav-pills.nav-fill.nav-modal', lists))
            elements.push(el('.tab-content', contents))
        }

        if (this._modalContent) {
            elements.push(this._modalContent)
        }

        if (this._inputs.length > 0) {
            this._inputElements = map(this._inputs, (item) => {
                let attributes = merge(item.attrs, {id: item.name})
                attributes['name'] = item.name
                const initialValue = elem.getAttribute(item.name) || get(item, 'attrs.value')
                if (initialValue) attributes['value'] = initialValue

                if (attributes['type'] == 'hidden') {
                    return el(item.element, attributes)
                } else {
                    return el('.form-group',
                        el('label', item.label, {for: item.name}),
                        el(item.element, attributes),
                    )
                }
            })

            this._modalForm = el('form', this._inputElements, this.csrfToken())
            this._modalForm.onsubmit = (ev) => {
                ev.preventDefault()
            }
            elements.push(this._modalForm)
        }

        if (this._customInputs) {
            this._modalForm = el('form', this._customInputs, this.csrfToken())
            this._modalForm.onsubmit = (ev) => {
                ev.preventDefault()
            }
            elements.push(this._modalForm)
        }

        return el('.modal-body', elements)
    }

    protected resetInputValues() {
        each(this._inputElements, (element) => {
            const input = element.querySelector('input') as HTMLInputElement
            if (!input) return

            // @FIXME: add support to set default value via input options
            input.value = this._parentElement.getAttribute(input.getAttribute('name'))
        })
    }

    protected constructFooter() {
        this._buttonElements = map(this._buttons, (item) => {
            let btn;

            if (item.icon) {
                btn = el(item.element, el('i.' + item.icon), item.text, item.attrs)
            } else {
                btn = el(item.element, item.text, item.attrs)
            }

            if (item.callback) {
                btn.onclick = item.callback.bind(this)
            }

            return btn as HTMLButtonElement
        })

        if (this._modalPartBorder) {
            return el('.modal-footer', this._buttonElements)
        } else {
            return el('.modal-footer.border-0', this._buttonElements)
        }
    }

    protected updateTable() {
        if (this._tables){
            let self = this
            let contents = []
            let tabContent = this._modal._element.querySelector('.tab-content')

            map(this._tables, (item) => {
                let table_heads = [],
                    table_rows = []

                if (item.table_rows) {
                    table_heads = self.drawTableHeader(item.table_heads)
                    table_rows = self.drawTableRow(item.table_rows)

                    contents.push(
                        el('div', item.table_attributes,
                            el('.table-responsive',
                                el('table.table.table-hover',
                                    el('thead', table_heads),
                                    el('tbody', table_rows)
                                )
                            )
                        )
                    )
                }
            })

            if (contents.length > 0) {
                tabContent.innerHTML = ''
                map(contents, (content) => {
                    mount(tabContent, content)
                })
            }
        }
    }

    private drawTableHeader(table_header_columns: any) {
        let table_headers = []

        map(table_header_columns, (column) => {
            table_headers.push(el('th', column.text, column.attributes))
        })

        return table_headers
    }

    private drawTableRow(table_rows: any) {
        let rows = []

        map(table_rows, (row) => {
            let row_contents = []

            map(row.columns, (column) => {
                if(column.element){
                    row_contents.push(
                        el('td', column.attributes,
                            el(column.element, column.text)
                        )
                    )
                }else{
                    row_contents.push(
                        el('td', column.text, column.attributes)
                    )
                }
            })

            rows.push(el('tr', row_contents))
        })

        return rows
    }

    protected serializeForm() {
        return $(this._modalForm).serialize()
    }

    protected csrfToken() {
        const csrfTokenValue = this.csrfTokenValue()
        if (!csrfTokenValue) return null

        return el('input', {value: csrfTokenValue, type: 'hidden', name: 'authenticity_token'})
    }

    protected csrfTokenValue() {
        const csrfToken = document.querySelector('[name="csrf-token"]') as HTMLMetaElement
        if (!csrfToken) return null

        return csrfToken.content
    }

    protected cancelButtonClick() {
        this._modal.hide()
        this._modalCancelCallback(this.serializeForm())
    }

    protected saveButtonClick() {
        this._modalSaveCallback(this.serializeForm())
    }

    protected renderInputErrors(input: HTMLInputElement, message: string) {
        let parent = input.closest('.form-group'),
            messages = parent.querySelector('.invalid-feedback')

        input.classList.remove('is-invalid')
        if (messages) messages.remove()

        input.classList.add('is-invalid')
        mount(parent, el('.invalid-feedback', message))
    }

    protected resetInputErrors(input: HTMLInputElement) {
        let parent = input.closest('.form-group'),
            messages = parent.querySelector('.invalid-feedback')

        input.classList.remove('is-invalid')
        if (messages) messages.remove()
    }
}
