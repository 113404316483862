import * as _ from 'lodash'
import {el, mount, text} from 'redom'
import {each} from 'lodash'

export enum AlertType {
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info'
}

export interface AlertData {
    title: string
    content: string
    type?: AlertType
    dismissable?: boolean
    icon?: boolean
    with_title?: boolean
}

/**
 * Wrapper implementation of Bootstrap's Alert to reduce boilerplate
 * templates.
 *
 * Available alert type:
 * - success
 * - danger
 * - warning
 * - info
 *
 * Usage:
 * new Alert(containerElement) initialize Alert component
 * add(data, scrollTop) add new alert
 *   data: {title:, content:, type: AlertType.INFO, dismissable:, icon:}
 */
export class Alert {
    protected readonly container: HTMLElement
    protected title: string
    protected content: string
    protected type: AlertType
    protected dismissable: boolean
    protected icon: boolean
    protected alert: Element
    protected with_title: boolean

    constructor(container: HTMLElement) {
        this.container = container
    }

    add(data: AlertData, scrollTop: boolean = true, dismissOld: boolean = true) {
        const defaults = {
            type: AlertType.INFO,
            dismissable: true,
            icon: true,
            with_title: true
        }
        data = _.merge(defaults, data)

        this.title = data.title
        this.content = data.content
        this.type = data.type
        this.dismissable = data.dismissable
        this.icon = data.icon
        this.with_title = data.with_title

        if (dismissOld) this.dismiss()
        this.alert = this.constructAlert()
        mount(this.container, this.alert)

        if (scrollTop) {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }

        return this.alert
    }

    dismiss() {
        each(this.container.children, (item) => {
            item.remove()
        })
    }

    static setAlertsExpandable() {
        let alerts = document.querySelectorAll('.alert.alert-expandable')
        each(alerts, (alert: HTMLDivElement) => {
            let content = alert.querySelector('.alert-content'),
                paragraphs = alert.querySelectorAll('p:not(:first-child)')

            each(paragraphs, (paragraph: HTMLParagraphElement, index) => {
                paragraph.style.display = 'none'
            })

            let showMore = el('a.show-more.shown', text('Show More'), {href: '#'}) as HTMLAnchorElement
            showMore.onclick = (ev) => {
                ev.preventDefault()

                if (showMore.classList.contains('shown')) {
                    showMore.textContent = 'Show Less'
                    showMore.classList.remove('shown')
                    each(paragraphs, (paragraph: HTMLParagraphElement) => {
                        paragraph.style.display = 'inline-block'
                    })
                } else {
                    showMore.textContent = 'Show More'
                    showMore.classList.add('shown')
                    each(paragraphs, (paragraph: HTMLParagraphElement) => {
                        paragraph.style.display = 'none'
                    })
                }
            }
            mount(content, showMore)
        })
    }

    protected constructAlert() {
        let elements: HTMLElement[] = []

        if (this.dismissable) {
            elements.push(
                el('button.close',
                    el('span', '×'),
                    {type: 'button', 'data-dismiss': 'alert'}
                )
            )
        }

        if (this.with_title) {
            elements.push(el('h4', this.title))
        }

        elements.push(el('.alert-content', this.content))

        let alert = el(
            '.alert.alert-dismissible.fade.show',
            elements,
            {role: 'alert'}
        )

        alert.classList.add(`alert-${this.type}`)
        if (this.icon) {
            alert.classList.add('alert-icon')
        }

        return alert
    }
}