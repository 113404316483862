import i18n from 'i18n'

export const PASSWORD_PATTERN = "^(?=.{10,40})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\[\\]#!$%&\\(\\)*+,.\\/:;<>=?@^_{|}~-])[^`]*$"
export const ORG_NAME_REGEX = "[A-Za-z0-9\\s.,\\'\\[\\]_\\/&|\\(\\)\\-!@]\\w+"
export const ORG_NAME_SYMBOL_REGEX = "(?=.*[a-zA-Z0-9])"
export const EMAIL_REGEX = "^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,}"
export const SUPPORT_TICKET_ATTACHMENT_TYPES =[
    'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slide',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-outlook',
    'text/csv',
    'text/plain',
    'message/rfc822',
    'text/html',
    'application/octet-stream',
    'application/pdf',
    'application/rtf',
    'image/png',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'video/quicktime',
    'video/mp4',
    'application/zip'
]

// TODO: make these configurable
export const MAX_ATTACHMENT_NUM = 5
export const MAX_ATTACHMENT_SIZE = 5
export const MAX_TOTAL_ATTACHMENT_SIZE = 25
export const SIZE_UNIT = 'MB'
export const REPLY_LIMIT = 3

/**
 * Backup statuses is generated from application_helper.rb and must be
 * kept in sync.
 */
export enum BackupStatuses {
    TESTING = 'testing',
    RUNNING = 'running',
    RUNNING_LT_24_HOURS = 'running_lt_24_hours',
    RUNNING_GT_24_HOURS = 'running_gt_24_hours',
    CONNECTING = 'connecting',
    QUEUED = 'queued',
    PREPARING = 'preparing',
    CANCELLING = 'cancelling',
    CANCELLED = 'cancelled',
    COMPLETED = 'completed',
    COMPLETED_NO_CHANGES = 'completed_no_changes',
    COMPLETED_LAST_24_HOURS = 'completed_last_24_hours',
    PARTIAL_COMPLETED = 'partial_completed',
    RETRYING = 'retrying',
    RETRY_ERROR = 'retry_error',
    SPACE_FULL = 'space_full',
    AUTH_ERROR = 'auth_error',
    CONN_ERROR = 'conn_error',
    FAILED = 'failed'
  }

/**
 * Support ticket statuses: Open, Closed
 */
export enum SupportTicketStatuses {
    OPEN = 'Open',
    CLOSED = 'Closed',
    INVESTIGATION = 'Investigation',
    REPLIED = 'Support Replied'
}

/**
 * URL naming enums for product type must kept in sync
 * with the Rails routes.
 */
export enum ProductType {
    EMAIL = 'email',
    DRIVE = 'drive',
    CONTACT = 'contact',
    CALENDAR = 'calendar',
    TASK = 'task',
    SHAREPOINT = 'sharepoint',
    SHARED_DRIVE = 'shared_drive',
    TEAMS = 'teams',
    PRIVATE_CHAT = 'ms_chat'
}

export enum ProductCategory {
    PERSONAL = 'personal',
    BACKUP = 'backup',
    ARCHIVER = 'archiver',
    NFP_BACKUP = 'nfp_backup',
    NFP_ARCHIVER = 'nfp_archiver',
    QBO = 'qbo_sku',
    ENTRA_BACKUP = 'entra_backup',
    ENTRA_ARCHIVER = 'entra_archiver',
}

/**
 * URL naming enums for product activity must kept in sync
 * with the Rails routes.
 */
export enum ProductActivity {
    BACKUP = 'backup',
    RESTORE = 'restore',
    DOWNLOAD = 'download',
    MIGRATE = 'migrate'
}

/**
 * Button condition
 */
export enum ButtonStatus {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    LOADING = 'loading'
}

export const STATUS_GROUPS = {
    email: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'space_full', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
        migrate: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
    },
    drive: {
        backup: ['running_lt_24_hours', 'running_gt_24_hours', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    shared_drive: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    contact: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    calendar: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    ms_chat: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    task: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    sharepoint: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    },
    teams: {
        backup: ['running', 'retrying', 'completed_last_24_hours', 'conn_error', 'auth_error'],
        restore: ['running', 'retrying', 'completed_last_24_hours', 'partial_completed', 'failed', 'conn_error', 'auth_error'],
        download: ['running', 'retrying', 'completed_last_24_hours', 'failed'],
    }
}

export enum ResponseStatus {
    SESSION_TIMEOUT = 403,
    UNAUTHORIZED = 401
}

export enum SupportTicketTopics {
    ACCOUNT = 'account',
    EMAIL_ACCOUNT = 'email_account',
    EMAIL_BACKUP = 'email_backup',
    EMAIL_DOWNLOAD = 'email_download',
    EMAIL_RESTORE = 'email_restore',
    EMAIL_MIGRATE = 'email_migrate',
    DRIVE_BACKUP = 'drive_backup',
    DRIVE_DOWNLOAD = 'drive_download',
    DRIVE_RESTORE = 'drive_restore',
    SHARED_DRIVE_BACKUP = 'shared_drive_backup',
    SHARED_DRIVE_DOWNLOAD = 'shared_drive_download',
    SHARED_DRIVE_RESTORE = 'shared_drive_restore',
    CCT_BACKUP = 'cct_backup',
    CCT_DOWNLOAD = 'cct_download',
    CCT_RESTORE = 'cct_restore',
    SHAREPOINT_BACKUP = 'sharepoint_backup',
    SHAREPOINT_DOWNLOAD = 'sharepoint_download',
    SHAREPOINT_RESTORE = 'sharepoint_restore',
    TEAMS_BACKUP = 'teams_backup',
    TEAMS_DOWNLOAD = 'teams_download',
    TEAMS_RESTORE = 'teams_restore'
}

export enum SupportTicketDirection {
  IN = 'in',
  OUT = 'out'
}

export const PERIOD_FREQUENCY =  {
    daily: i18n.t('views.user_management.notification.daily'),
    weekly: i18n.t('views.user_management.notification.weekly'),
    monthly: i18n.t('views.user_management.notification.monthly')
}

export const PlanLicense = {
  paid_plan: 'paid_plan',
  nfr: 'nfr'
}