import { ProductCategory } from 'app/constants/generals'
import i18n from 'i18n'

export class GeneralHelper {
    public static productCategoryToText(category) {
        switch(category) {
            case ProductCategory.PERSONAL:
                return 'Personal'
            case ProductCategory.BACKUP:
                return 'Backup'
            case ProductCategory.ARCHIVER:
                return 'Archiver'
            case ProductCategory.NFP_BACKUP:
                return 'Backup Non-profit'
            case ProductCategory.NFP_ARCHIVER:
                return 'Archiver Non-profit'
            case ProductCategory.QBO:
                return 'QBO SKU'
            case ProductCategory.ENTRA_BACKUP:
                return 'Entra Backup'
            case ProductCategory.ENTRA_ARCHIVER:
                return 'Entra Archiver'
            default:
                return category
        }
    }

    public static getCookie(cname) {
        let name = cname + "=",
            ca = document.cookie.split(';')

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return null
    }

    public static getCurrentLanguage() {
        i18n.locale = this.getCookie('selected_language_key')
        i18n.defaultLocale = this.getCookie('selected_language_key')
    }

    public static getFeatureFlagHash() {
        try {
            return JSON.parse((document.querySelector("#feature-flag") as HTMLElement)?.dataset.featureFlag)
        } catch(e) {
            return {}
        }
    }

    public static featureFlag(key) {
        return !!this.getFeatureFlagHash()[key]
    }
}

export class LocaleHelper {
    public static date(date: string, format: string = 'date.formats.custom_long') {
        return i18n.l(format, date)
    }
}